<template>
  <div class="data-user">
    <div class="box-setting-header">
      <div class="title">Compléter vos informations</div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <form @submit.prevent="handelUpdateData" v-if="getUserToUpdate">
      <div class="body">
        <div class="group-content">
          <div class="label-box-style w-1">
            <span class="title-tabel">Nom & Prénom</span>
          </div>
          <b-form-input
            autocomplete="off"
            id="Nom"
            v-model="getUserToUpdate.name"
            disabled
          ></b-form-input>
        </div>

        <div class="group-content">
          <div class="label-box-style w-1">
            <span class="title-tabel">E-mail</span>
          </div>
          <b-form-input
            autocomplete="off"
            id="email"
            v-model="getUserToUpdate.email"
            type="email"
            disabled
          ></b-form-input>
        </div>
        <div class="group-content group-content-password">
          <div class="label-box-style w-2">
            <span class="title-tabel">Nouveau mot de passe</span>
          </div>
          <b-form-input
            autocomplete="off"
            id="password"
            v-model="getUserToUpdate.password"
            type="password"
            @input="resetError"
            required
          ></b-form-input>
        </div>
        <div class="group-content group-content-password">
          <div class="label-box-style w-2">
            <span class="title-tabel">Confirmation de mot de passe</span>
          </div>
          <b-form-input
            autocomplete="off"
            type="password"
            id="password_confirmation"
            v-model="getUserToUpdate.password_confirmation"
            @input="checkConfirmationValidite"
            required
          ></b-form-input>
        </div>
        <div v-show="confirmPasswordValidation" class="error-password">
          La confirmation de mot de passe est incorrecte
        </div>
      </div>
      <b-button
        class="button-valide-style mt-4"
        size="sm"
        type="submit"
        :disabled="confirmPasswordValidation"
      >
        Valider
      </b-button>
      <div v-if="success" class="success">Modifié</div>
      <div v-if="getErrorInUpdateUser" class="error">
        <ul v-if="Array.isArray(getErrorInUpdateUser)">
          <li
            v-for="(error, index) in getErrorInUpdateUser"
            :key="'error' + index"
          >
            {{ error }}
          </li>
        </ul>
        <span v-else>{{ getErrorInUpdateUser }}</span>
      </div>
    </form>
    <b-modal
      id="modal-password"
      ref="modal-password"
      title="Saisie votre mot de passe actuel"
      @show="resetModal"
      @hidden="resetModal"
      :hide-footer="true"
    >
      <form ref="form" @submit.stop.prevent="UpdateDataUser">
        <b-form-group
          label=""
          label-for="password"
          invalid-feedback="password is required"
        >
          <b-form-input
            id="oldpassword"
            v-model="password"
            type="password"
            required
          ></b-form-input>
        </b-form-group>
        <div class="form-modal-custom-style">
          <div class="error" v-if="error">Mot de passe incorrect</div>
          <div class="actionModel">
            <b-button
              class="button-cancel-style ml-3 mt-3"
              @click="hideModal('modal-password')"
            >
              Annuler
            </b-button>
            <b-button
              variant="succes"
              class="button-valide-style ml-3 mt-3"
              type="submit"
            >
              Valider
              <div class="chargement" v-if="getLoadingUpdateInfoUser">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data() {
    return {
      success: false,
      password: null,
      nb_essai: 0,
      error: false,
      confirmPasswordValidation: false
    };
  },
  computed: {
    ...mapGetters([
      'getLoadingUpdateInfoUser',
      'getUserData',
      'getErrorInUpdateUser'
    ]),
    getUserToUpdate() {
      return {
        password: null,
        password_confirmation: null,
        ...this.getUserData
      };
    },
    computedPassworValidation() {
      if (
        this.getUserToUpdate &&
        this.getUserToUpdate.password &&
        this.getUserToUpdate.password != null &&
        this.getUserToUpdate.password !=
          this.getUserToUpdate.password_confirmation
      ) {
        return true;
      }
      return false;
    }
  },

  methods: {
    ...mapActions([
      'editUserInfo',
      'verificationUser',
      'logOut',
      'resetErrorUserUpdate'
    ]),
    ...mapMutations(['SET_LOADING_INFO_USER', 'SET_ERROR_EDIT_UESER']),
    resetError() {
      this.success = false;
      this.resetErrorUserUpdate();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.password = null;
    },
    async handelUpdateData() {
      if (
        this.getUserToUpdate.password &&
        this.getUserToUpdate.password_confirmation
      ) {
        this.$refs['modal-password'].show();
      }
    },
    async UpdateDataUser() {
      this.error = false;
      this.SET_LOADING_INFO_USER(true);

      this.success = false;
      const response = await this.verificationUser({
        password: this.password,
        nb_essai: this.nb_essai
      });
      if (response === true) {
        const responce = await this.editUserInfo(this.getUserToUpdate);
        if (responce === true) {
          this.success = true;
          this.getUserToUpdate.password = null;
          this.getUserToUpdate.password_confirmation = null;
          this.SET_LOADING_INFO_USER(false);
          this.hideModal('modal-password');
        } else {
          this.SET_LOADING_INFO_USER(false);
          this.hideModal('modal-password');
        }
      } else {
        this.SET_LOADING_INFO_USER(false);
        this.error = true;
        this.nb_essai = this.nb_essai + 1;
      }
    },
    checkConfirmationValidite() {
      this.resetErrorUserUpdate();
      if (
        this.getUserToUpdate &&
        this.getUserToUpdate.password &&
        this.getUserToUpdate.password != null &&
        this.getUserToUpdate.password !=
          this.getUserToUpdate.password_confirmation
      ) {
        if (
          this.getUserToUpdate.password_confirmation == '' ||
          this.getUserToUpdate.password_confirmation == null ||
          this.getUserToUpdate.password_confirmation.length == 0
        ) {
          this.confirmPasswordValidation = false;
        } else {
          this.confirmPasswordValidation = true;
        }
      } else {
        this.confirmPasswordValidation = false;
      }
    }
  },

  mounted() {
    this.success = false;
  }
};
</script>

<style lang="scss" scoped>
.data-user {
  display: block;
  padding: 10px;
  margin: 0px;
  min-height: 100%;
  width: 100%;
  overflow-y: auto;
  form {
    .body {
      display: block;
      position: relative;
      width: 40%;
      .group-content-password {
        width: 50% !important;
      }
      .group-content {
        width: 40%;
        margin: 0px auto;
        margin-top: 15px;
        display: inline;
        .label-box-style {
          padding: 8px;
          height: 35px;
          text-align: start;
          background-color: transparent;
        }
        .title-tabel {
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
          font-weight: 600;
          color: #2a2a2a;
        }
        input {
          font: inherit;
          width: 100%;
          padding: 10px;
          box-sizing: border-box;
          border: 1px solid #70707012;
          border-radius: 17px;
          font-size: 14px;
          background-color: #f5f5f5;
        }
      }
    }

    .error {
      color: red;
      font-size: 14px;
      text-align: center;
    }
    .success {
      color: green;
      font-size: 14px;
      text-align: center;
    }
  }
}
.form-group {
  margin-bottom: 0px !important;
}
.error {
  color: red;
  font-size: 14px;
  text-align: center;
}
#oldpassword {
  border-radius: 22px;
}
.error-password {
  color: red;
  font-size: 10px;
  margin-top: 4px;
  text-align: center;
  font-weight: 700;
}
</style>
